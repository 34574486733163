/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#root {
    height: 100%;
}

div:has(.highcharts-container) {
    border-radius: .375rem;
}

main.container {
    margin-top: 4em;
    margin-bottom: 4em;
    width: 100vw;
    min-height: 80vh;
    min-width: 80vw;
}

nav > .container {
    min-width: 80vw;
}

/*body {
    min-height: 100vh;
}*/